/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PREVIOUS_ROUTE } from 'Component/NavigationAbstract/NavigationAbstract.config';
import Router from 'Component/Router';
import {
    App as SourceApp
} from 'SourceComponent/App/App.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import EventManager from 'Util/Events/EventManager';
import history from 'Util/History';
import {
    checkForCacheUpdates,
    debounce
} from 'Util/Request';

import { FIRST_PAGE_VISIT_DELAY, PAGE_VISIT_DELAY } from './App.config';

/** @namespace Satisfly/Component/App/Component */
export class App extends SourceApp {
    state = {
        isSomethingWentWrong: false,
        errorDetails: {},
        actualPathname: ''
    };

    componentDidMount() {
        setTimeout(() => {
            this.registerPageVisit();
        }, FIRST_PAGE_VISIT_DELAY);

        this.unlisten = history.listen(debounce((location) => {
            const { actualPathname } = this.state;

            if (actualPathname !== location.pathname) {
                BrowserDatabase.setItem(actualPathname, PREVIOUS_ROUTE);

                this.registerPageVisit(location);
            }

            this.setState({ actualPathname: location.pathname });
        }, PAGE_VISIT_DELAY));

        checkForCacheUpdates();
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
            BrowserDatabase.deleteItem(PREVIOUS_ROUTE);
        }
    }

    registerPageVisit(next = '') {
        const { SR } = window;

        this.setState({ actualPathname: next.pathname });

        SR.event.pageVisit()
            .then(
                /** @namespace Satisfly/Component/App/Component/App/registerPageVisit/pageVisit/then */
                () => {
                    SR.dynamicContent.get();
                }
            );
    }

    renderRouter() {
        return (
            <>
                <EventManager />
                <Router key="router" />
            </>

        );
    }
}

export default App;
