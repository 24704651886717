/* eslint-disable func-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Satisfly/Util/Events/EventManager/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer.id
});

/** @namespace Satisfly/Util/Events/EventManager/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    addProductToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    )
});

/** @namespace Satisfly/Util/Events/EventManager */
export class EventManager extends PureComponent {
    static propTypes = {
        addProductToCart: PropTypes.func.isRequired,
        cartId: PropTypes.string,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        cartId: ''
    };

    componentDidMount() {
        const { cartId, addProductToCart, showNotification } = this.props;

        const getProductForMagento = this.getProductForMagento.bind(this);

        window.addProductToCart = async function (sku, quantity) {
            const products = getProductForMagento(sku, quantity);

            if (!isSignedIn()) {
                showNotification('info', __('Sign In'));

                return null;
            }

            try {
                await addProductToCart({ products, cartId });
            } catch (error) {
                showNotification('info', error.message);
            }
        };
    }

    getProductForMagento(sku, quantity) {
        const productData = [];

        if (sku) {
            productData.push({
                sku,
                quantity,
                entered_options: [],
                selected_options: []
            });
        }

        return productData;
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventManager);
